/* Loading.css */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 5px;
  animation: loading-animation 1.5s infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes loading-animation {
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
}